import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserBody from '../../components/TeaserBody'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import { graphql } from 'gatsby'
import Schedule from '../../components/Schedule'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = ({ data }) => {

  const now = new Date()
  const schedules = data.allTermineJson.edges
    .filter(scheduleNode => new Date(scheduleNode.schedule.endDate) >= now)
    .map(scheduleNode => scheduleNode.schedule)

  return (
    <React.Fragment>
      <Seo title="Mit lebendiger Architekturdokumentation und jQAssistant zum perfekten System - Workshop"
           description="In diesem Workshop zeigen wir Ihnen nicht, wie wir langweilige Dokumentation schreiben, die bereits beim Erstellen
            schon wieder veraltet ist. Stattdessen wissen Sie nach dem Workshop, wie Sie Ihre Architekturdokumentation mit
            technischen Finessen lebendig und aktuell halten, um langfristig davon zu profitieren. "
      />

      <BigTitle title="Mit lebendiger Architekturdokumentation und jQAssistant zum perfekten System"
                className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Architektur.jpg'}
                menuInverted={true} logoInverted={true}>
        - Workshop -
      </BigTitle>
      <DefaultSection>
        <Article>
          <h2 className="h1">Architektur und Dokumentation im Einklang mit den Anforderungen – von Anfang an!</h2>
          <p>
            Geht es Ihnen auch so? Sie arbeiten an einem Softwaresystem und wollen es möglichst perfekt umsetzen: Angefangen
            bei der Definition der Architektur, über die Dokumentation und Umsetzung bis hin zur Kontrolle der Einhaltung der
            Architektur. Jedoch wird der Projektalltag Sie und insbesondere Ihr perfektes Softwaresystem früher oder später einholen.
            Bereits nach wenigen Monaten stehen Sie dann vor den gleichen Problemen, vor denen Sie auch bei den letzten Systemen standen:
            Schlechte Wartbarkeit, mangelhafte Testbarkeit, komplizierte Releaseprozesse und Probleme in der Produktion, welche aufgrund
            komplexer Codestrukturen und unvollständiger Dokumentation nur schwer gelöst werden können. Und nun? Wir haben den Ausweg
            aus diesem Teufelskreis.
          </p>
          <p>
            In diesem Workshop zeigen wir Ihnen nicht, wie wir langweilige Dokumentation schreiben, die bereits beim Erstellen
            schon wieder veraltet ist. Stattdessen wissen Sie nach dem Workshop, wie Sie Ihre Architekturdokumentation mit
            technischen Finessen lebendig und aktuell halten, um langfristig davon zu profitieren. Sie lernen Mittel und Wege – wie Arc42 und jQAssistant – kennen,
            um Softwaresysteme rund um Qualitätsziele und Fachlichkeit nachhaltig zu entwerfen, umzusetzen und vor dem Verfall zu sichern.
          </p>
          <p>Lassen Sie uns gemeinsam den Traum vom perfekten System für Neu- und Bestandssysteme verwirklichen.</p>
          <p className="text-center-sm">
            <NavigationLink to={'#termine'} asActionButton buttonClassName="btn-outline-black">Termin
              auswählen</NavigationLink></p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-2">

        <CardDeck cardsPerRow={2}>
          <Card>
            <p className="subTitle text-muted">Zielgruppe</p>
            <h2>Wer sollte teilnehmen?</h2>

            <p className="mt-4">
              Alle Entwickler und Architekten, die wissen möchten, wie sie Architektur und Dokumentation richtig und nachhaltig mit modernen Tools im eigenen Projekt umsetzen können. Dabei profitieren sowohl Neu- als auch Bestandsprojekte maximal von den übertragbaren Vorlagen, Methoden und Tools.
            </p>
          </Card>
          <Card>
            <p className="subTitle text-muted">Vorkenntnisse und Anforderungen</p>
            <h2>Was wird benötigt?</h2>
            <p>
              <ul>
                <li>sicherer Umgang mit einer IDE und Git</li>
                <li>solides Wissen über Java</li>
                <li>eigenes entwicklungsgeeignetes Notebook mit IDE</li>
              </ul>
            </p>
          </Card>
        </CardDeck>

      </DefaultSection>
      <DefaultSection>
        <Article>
          <h2 className="h1">Inhalte</h2>
          <p>
            Der zweitägige Workshop erarbeitet am Beispielprojekt ausgehend von fachlichen Anforderungen unterschiedliche
            Dokumentations- und Codeartefakte und zeigt, wie sich diese zwei Welten miteinander optimal verbinden lassen.
            Konkrete Inhalte sind:
          </p>
          <p>
            Entwurf und Dokumentation der fachlichen Architektur
            <ul>
              <li>Verstehen fachlicher Anforderungen und Use Cases</li>
              <li>Entwurf und Dokumentation fachlicher Dekompositionen der Problemdomäne</li>
              <li>Ansätze u. a.: Domain Storytelling, Context Mapping, Event Storming</li>
            </ul>
          </p>

          <p>
            Entwurf und Dokumentation der technischen Architektur
            <ul>
              <li>Identifikation von Qualitätszielen und Ableitung technischer Anforderungen aus diesen</li>
              <li>Entwurf und Dokumentation der Architektur auf verschiedenen Abstraktionsebenen</li>
              <li>Definition relevanter Architekturkonzepte und -regeln</li>
              <li>Ansätze u. a.: ISO 25010, C4-Model, PlantUML</li>
            </ul>
          </p>

          <p>
            Kommunikation der Architektur
            <ul>
              <li>Festhalten getroffener Entscheidungen und Gründe für diese</li>
              <li>Erarbeitung von Entwicklungsrichtlinien zur konsistenten und korrekten Umsetzung der Architektur</li>
              <li>Ansätze u. a.: Arc42, Architecture Decision Records, Architekturexpliziter Code</li>
            </ul>
          </p>

          <p>
            Abgleich von Soll- und Ist-Architektur zur Vermeidung von Abweichungen
            <ul>
              <li>Anreicherung der Dokumentation um Möglichkeiten zur Validierung der Implementierung gegen den dokumentierten Soll-Zustand</li>
              <li>Generierung von Dokumentation auf Basis tatsächlich implementierter Strukturen</li>
              <li>Ansätze u. a.: Lebendige Dokumentation, jQAssistant</li>
            </ul>
          </p>

        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-2" anchor={'termine'}>
        <TeaserHeader hasBody title="Termine">
          <p>
            Wählen Sie aus den angebotenen Terminen den für Sie passenden oder kontaktieren Sie uns. Gern erstellen wir
            Ihnen ein individuelles Angebot, das zeitlich besser für Sie passt.
          </p>
        </TeaserHeader>
        <TeaserBody>
          {schedules && schedules.map(schedule => (
            <div className="mb-5" key={schedule.id}>
              <Schedule schedule={schedule} showDetails/>
            </div>
          ))}
          <p>
            Gerne können Sie den Workshop auch inhouse für Ihr ganzes Team buchen. Für Gruppenrabatte kommen Sie bitte
            telefonisch unter <a className="phone-link"
                                 aria-label="Telefonnummer BUSCHMAIS GbR"
                                 href="tel:+493513209230">+49 351 3209230</a> oder
            per E-Mail an <a
            className="phone-link" href="mailto:info@buschmais.com"
            aria-label="E-Mail-Kontakt BUSCHMAIS">info@buschmais.com</a> auf uns zu.<br/><br/>Wir freuen uns, von Ihnen
            zu
            hören.
          </p>
        </TeaserBody>
      </TeaserSection>

      <IndividualContactSection personKey={'stephan'}/>

    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    {
        allTermineJson(sort: {fields: startDate}, filter: {eventKey: {eq: "workshop-dokumentation"}}) {
            edges {
                schedule: node {
                    id
                    title
                    category
                    labels
                    descriptionShort
                    startDate
                    endDate
                    personKeys
                    locationKey
                    linkDetails
                    linkBooking
                    price
                    capacity
                }
            }
        }
    }
`
